<template>
  <footer class="app-footer flex column">
    <!-- FOOTER -->
  </footer>
</template>

<script>
export default {
  name: "default_AppFooter",
}
</script>

<style lang="scss">
@import '@/assets/styles/global/index';
.defaultApp {
  .app-footer {

  }
}
</style>