<template>
  <div class="organization-filter">

  </div>
</template>

<script>
export default {
  name: 'OrganizationFilter'
}
</script>

<style lang="scss">
.megaphon-app {
  
}
</style>