const  en = {
  sidebar: {
    folders: 'Folders',
    megaphonGeneral: 'Megaphon general',
    addFolder: 'Add folder'
  }
}
const he = {
  sidebar: {
    folders: 'תיקיות',
    megaphonGeneral: 'מגפון כללי',
    addFolder: 'צור תיקייה'
  }
}
const heF = {
  ...he,
}
export const commonLocales = {
  en,
  he,
  heF
}