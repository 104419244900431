<template>
  <div class="account-filter">

  </div>
</template>

<script>
export default {
  name: 'AccountFilter'
}
</script>

<style lang="scss">
.megaphon-app {
  
}
</style>